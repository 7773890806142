import { THEME_COLORS } from '~/assets/javascript/constants';
import { calcAPCA } from 'apca-w3';

export const TRANSLUCENT_WHITE = `${THEME_COLORS.base.white}d9`;
export const TRANSLUCENT_BLACK = `${THEME_COLORS.base.black}a6`;

export const getAccessibleColor = (backgroundColorHex, darkMode) => {
  const whiteContrastDifference = parseFloat(Math.abs(calcAPCA(TRANSLUCENT_WHITE, backgroundColorHex)).toFixed(1));
  const blackContrastDifference = parseFloat(Math.abs(calcAPCA(TRANSLUCENT_BLACK, backgroundColorHex)).toFixed(1));
  const result = whiteContrastDifference >= blackContrastDifference;

  if (darkMode) {
    return result ? TRANSLUCENT_BLACK : TRANSLUCENT_WHITE;
  }

  return result ? TRANSLUCENT_WHITE : TRANSLUCENT_BLACK;
};
